const controlKeys = {
  enterKey: 13,
  leftKey: 37,
  upKey: 38,
  rightKey: 39,
  downKey: 40,
  backKey: 10009,
  exitKey: 10182
};

export default controlKeys;