<template>
  <div class="template-auth">
    <div v-if="isAuth">
      <p>
        Você já está autenticado!
      </p>
    </div>

    <div v-if="!isAuth">
      <p>Insira o código abaixo no painel para adicionar este player!</p>
      <div class="access-code">
        <span v-for="(char, index) in accessCodeChars" :key="index" class="access-code-char">
          {{ char }}
        </span>
      </div>

      <div class="timer-update">
        Tempo para próxima atualização: {{ formattedTimeLeft }}
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import apiConfig from '@/config/apiConfig';

export default {
  name: 'CodeAuth', 
  data() {
    return {
      accessCode: '',
      accessCodeChars: [],
      showComponent: true,
      isAuth: false,
      timeLeft: 300,
      nextRefresh: new Date(Date.now() + 300000),
    }
  },
  created() {
    this.isAuth = window.localStorage.getItem('isAuth') === 'true';

    this.getAccess();

    const refreshData = () => {
      this.timeLeft -= 1;
      this.nextRefresh.setSeconds(this.nextRefresh.getSeconds() + 1);

      if (this.timeLeft <= 0) {
        this.getAccess();
        this.timeLeft = 300;
        this.nextRefresh = new Date(Date.now() + 300000);
      }

      setTimeout(refreshData, 1000);
    };

    refreshData();
  },
  methods: {
    async getAccess() {
      axios.get(apiConfig.dev.apiUrlInfo, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': apiConfig.dev.apiKey
        }
      })
        .then(response => {
          this.accessCode = response.data.data;

          if (typeof this.accessCode === "number") {
            this.accessCodeChars = this.accessCode.toString().split('');

            window.localStorage.setItem('isAuth', false);

            this.isAuth = false;
          }
          
          if (this.accessCode instanceof Object) {
            this.isAuth = true;

            window.localStorage.setItem('isAuth', true)
          }
        })
        .catch(error => {
          console.log(error)
        });
    },
  },
  computed: {
    formattedTimeLeft() {
      const minutes = Math.floor(this.timeLeft / 60).toString().padStart(2, '0');
      const seconds = (this.timeLeft % 60).toString().padStart(2, '0');
      return `${minutes}:${seconds}`;
    }
  },
}
</script>

<style>
  @import './code-auth.css';
</style>
