function isDateValid(dataIni, dataFim) {
  if (dataIni === "0000-00-00" || dataFim === "0000-00-00") {
    return true;
  }

  const now = new Date();
  const startDate = new Date(dataIni.split('-').reverse().join('-'));
  const endDate = new Date(dataFim.split('-').reverse().join('-'));
  return startDate <= now && endDate >= now;
}

function isTimeValid(horaIncial, horaFinal) {
  const now = new Date();
  const start = new Date();
  const end = new Date();
  start.setHours(horaIncial.split(":")[0], horaIncial.split(":")[1], horaIncial.split(":")[2]);
  end.setHours(horaFinal.split(":")[0], horaFinal.split(":")[1], horaFinal.split(":")[2]);
  return horaIncial === "00:00:00" && horaFinal === "23:59:59" || (start <= now && end >= now);
}

function isDaysValid(dias) {
  const dayOfWeek = new Date().getDay();
  return dias.charAt(dayOfWeek) === '1' && dias.indexOf('0') === -1;
}

function verifyCampaign(campaign) {
  const { dataIni, dataFim, horaIncial, horaFinal, dias } = campaign;
  return isDateValid(dataIni, dataFim) && isTimeValid(horaIncial, horaFinal) && isDaysValid(dias);
}

function verifyCampaigns(campaigns) {
  return campaigns.filter((campaign) => verifyCampaign(campaign));
}

export function verifyMidias(campaigns) {
  const validCampaigns = verifyCampaigns(campaigns);
  const validMidias = [];

  validCampaigns.forEach((campaign) => {
    campaign.midias.forEach((media) => {
      const { dataIni, dataFim, horaIncial, horaFinal, mid_dias } = media;
      if (isDateValid(dataIni, dataFim) && isTimeValid(horaIncial, horaFinal) && isDaysValid(mid_dias)) {
        validMidias.push(media);
      }
    });
  });

  return validMidias || [];
}
