<template>
  <div class="video-player" tabindex="0" @keyup.enter="closeVideo">
    <video
      ref="videoPlayer"
      :src="currentVideo.vimeo"
      @ended="playNextVideo"
      @loadeddata="handleVideoLoaded"
      @click="playCurrentVideo"
    ></video>
    <div v-if="isLoading" class="loading-overlay">
      <div class="loading-spinner"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    midias: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentVideoIndex: 0,
      currentVideo: {},
      isPlaying: false,
      isLoading: true,
    };
  },
  created() {
    document.addEventListener("keydown", this.handleKeyDown);

    this.currentVideo = this.midias[this.currentVideoIndex];
  },
  destroyed() {
    document.removeEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    playNextVideo() {
      if (this.midias.length === 1) {
        this.playCurrentVideo();
      } else {
        this.currentVideoIndex = (this.currentVideoIndex + 1) % this.midias.length;
        this.currentVideo = this.midias[this.currentVideoIndex];
        this.isLoading = true;
        this.playCurrentVideo();
      }
    },
    playCurrentVideo() {
      const videoPlayer = this.$refs.videoPlayer;

      if (videoPlayer.paused) {
        videoPlayer.play();
        this.isPlaying = true;
      } else {
        videoPlayer.pause();
        this.isPlaying = false;
      }
    },
    handleVideoLoaded() {
      this.isLoading = false;

      const videoElement = this.$refs.videoPlayer;
      const videoHeight = videoElement.videoHeight;
      const videoWidth = videoElement.videoWidth;

      if (videoHeight > videoWidth) {
        videoElement.classList.add('rotate');
      } else {
        videoElement.classList.remove('rotate');
      }

      this.playCurrentVideo()
    },
    closeVideo() {
      this.$emit('close');
    },
  },
};
</script>

<style>
  @import './video-player.css';
  @import '@fortawesome/fontawesome-free/css/all.css';
</style>
