import axios from "axios";
import apiConfig from "@/config/apiConfig";
import validateMedias from './validatorMedias';
import formatDate from "@/config/dateFormatter";
import reportMidias from "./informTerminalMidias";
import { reportPrintScreen } from "./informTerminalPrintScreen";
import { verifyMidias } from "./verifyMidias";

async function getContext() {
  try {
    const response = await axios.get(apiConfig.dev.apiUrlInfo, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': apiConfig.dev.apiKey
      }
    });
    
    if (typeof response.data.data === "number") {
      return 'Você ainda não está autenticado.'
    }
    
    if (response.data.data instanceof Object) {
      return response;
    }
  } catch (error) {
    console.log(error)
  }
}

async function getList(context) {
  try {
    const response = await axios.get(`${apiConfig.dev.apiUrlMedia}${context.data.access.key}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization':  apiConfig.dev.apiKey
      }
    });

    return response.data.data;
  } catch (error) {
    console.log(error);
  }
}

export async function verifyContext() {
  const { data: context } = await getContext();
  let lastUpdatedStorage = window.localStorage.getItem('last_updated');

  if (lastUpdatedStorage === null) {
    lastUpdatedStorage = "";
  }

  const responseBanco = await validateMedias();
  
  if (context.data.status_lcd !== "S") {
    return {
      midias: { vimeo: apiConfig.dev.apiUrlIndisponivel },
      info: { 
        id: responseBanco.data.data.id_lcd, 
        banco: responseBanco.data.data.banco,
        reload: responseBanco.data.data.reload
      }
    };
  }

  if (lastUpdatedStorage === null) {
    window.localStorage.setItem('last_updated', JSON.stringify(context.data.last_update));
    window.localStorage.setItem('context', JSON.stringify(context));
  }

  const dateLastUpdateStorage = formatDate(lastUpdatedStorage)
  const dateLastUpdateContext = formatDate(context.data.last_update)

  if (dateLastUpdateStorage < dateLastUpdateContext) {
    const midiasTerminal = await getList(context);
    const filteredMidias = verifyMidias(midiasTerminal)

    await reportMidias(responseBanco, filteredMidias)
    await reportPrintScreen(context.data.data)

    window.localStorage.setItem('midias', JSON.stringify(filteredMidias))
    window.localStorage.setItem('last_updated', JSON.stringify(context.data.last_update));
    window.localStorage.setItem('context', JSON.stringify(context));

    return {
      midias: filteredMidias || [],
      info: { 
        id: responseBanco.data.data.id_lcd, 
        banco: responseBanco.data.data.banco,
        reload: responseBanco.data.data.reload
      }
    };
  } else {
    const midiasStorage = window.localStorage.getItem('midias');

    return {
      midias: JSON.parse(midiasStorage ?? "[]"),
      info: { 
        id: responseBanco.data.data.id_lcd, 
        banco: responseBanco.data.data.banco ,
        reload: responseBanco.data.data.reload
      }
    };
  }
}


