let deviceId = localStorage.getItem('deviceId');

// if (process.env.ENV_AMBIENT === 'dev') {
//   deviceId = 'teste-player'

//   localStorage.setItem('deviceId', deviceId);
// }

if (!deviceId) {
  deviceId = Date.now().toString(36) + Math.random().toString(36).substring(2, 7);
  
  localStorage.setItem('deviceId', deviceId);
}

const apiConfig = {
  dev: {
    apiUrlInfo: `${process.env.WIPLAY_API_URL_INFO}player=${deviceId}`,
    apiUrlIdentification: `${process.env.WIPLAY_API_URL_IDENTIFICATION}player=${deviceId}`,
    apiUrlMedia: `${process.env.WIPLAY_API_URL_MEDIA}`,
    apiUrlIndisponivel: `${process.env.WIPLAY_API_URL_UNAVAILABLE}`,
    apiKey: `${process.env.WIPLAY_API_URL_KEY}`,
  }
};

export default apiConfig;