export const messages = {
  "pt-BR": {
    introWiplay: 'Olá seja bem vindo ao Wiplay®',
    copyrightWiplay: 'Wiplay ® - Todos os direitos reservados',
    connectSuccess: 'Você está conectado.',
    connectError: 'Parece que seu terminal está sem internet.',
    authError: 'Você precisa estar com internet para autenticar o terminal.',
    midiasSuccess: 'Midias verificadas. Você pode iniciar a visualização!',
    midiasError: 'Você precisa estar autenticado.'
  },
  es: {
    introWiplay: 'Bienvenido a Wiplay®',
    copyrightWiplay: 'Wiplay® - Todos los derechos reservados',
    connectSuccess: 'Estás conectado.',
    connectError: 'Parece que tu terminal está sin conexión.',
    authError: 'Necesitas estar conectado para autenticar el terminal.',
    midiasSuccess: 'Medios verificados. ¡Puedes comenzar la reproducción!',
    midiasError: 'Necesitas estar autenticado.'
  },
  default: {
    introWiplay: 'Welcome to Wiplay®',
    copyrightWiplay: 'Wiplay® - All rights reserved',
    connectSuccess: 'You are connected.',
    connectError: 'It seems like your terminal is offline.',
    authError: 'You need to be online to authenticate the terminal.',
    midiasSuccess: 'Media verified. You can start the playback!',
    midiasError: 'You need to be authenticated.'
  }
};

