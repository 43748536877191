<script>
  import LoginPage from "./views/login-page/login-page.vue"

  export default {
    name: 'App',
    components: {
      LoginPage
    }
  }
</script>

<template>
  <div>
    <LoginPage></LoginPage>
  </div>
</template>

<style>
  @import "./App.css";
</style>
