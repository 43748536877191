<template>
  <div class="login-container">
    <div class="img-carousel">
      <img :src="backgroundImageUrl" />
    </div>
    <div class="menu-sidebar">
      <div class="menu-header">
        <img src="assets/logo/logo.png" />
        <p>{{ introWiplay }}</p>
      </div>

      <div class="menu-nav">
        <iconsNav />
      </div>

      <div class="menu-footer">
        <p>{{ copyrightsWiplay }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import iconsNav from '@/components/menu-nav/menu-nav.vue';
import { messages } from '@/config/langSelector';

export default {
  name: 'LoginPage',
  components: {
    iconsNav,
  },
  data() {
    return {
      introWiplay: '',
      copyrightsWiplay: '',
      backgroundImage: '',
    };
  },
  methods: {
    showIntroLanguage() {
      const systemLanguage = navigator.language;
      const { introWiplay, copyrightWiplay } =
        messages[systemLanguage] || messages.default;

      this.introWiplay = introWiplay;
      this.copyrightsWiplay = copyrightWiplay;
    },
  },
  computed: {
    backgroundImageUrl() {
      const randomNumber = Math.floor(Math.random() * 4) + 1;
      const formattedNumber = randomNumber.toString().padStart(2, '0');
      return `assets/img/BG-${formattedNumber}.jpg`;
    },
  },
  created() {
    this.showIntroLanguage();
  },
};
</script>

<style>
@import "./login-page.css";
</style>
