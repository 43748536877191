import axios from "axios";
import apiConfig from "@/config/apiConfig";
import optionsDate from "@/config/dateConfig";

export default async function reportMidias(contextTerminal, filteredMidias) {
  const context = await contextTerminal;
  const midias = await filteredMidias;

  for (let midia in midias) {
    let reportMidia = [{
      "id_publicidade": midias[midia].mid_id,
      "id_editoria": midias[midia].id_editoria_ds,
      "id_feed": midias[midia].id_arquivo_ds,
      "tp_editoria": midias[midia].id_editoria_ds === 0 ? 0 : 1,
      "data_exibicao": new Date().toLocaleString('en-GB', optionsDate).replace(',', ''),
    }]

    try {
      const data = new FormData();
      data.append('id_player', context.data.data.id_lcd);
      data.append('id_banco', context.data.data.banco);
      data.append('data', JSON.stringify(reportMidia));
  
      await axios.post(
        'https://api.wiplay.com.br/monitoramento/insert/midia/player',
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': apiConfig.dev.apiKey
          }
        }
      );
    }
    catch (error) {
      console.log(error);
    }
  }
}
