export default function formatDate(date) {
  const newDate = date
      .replace(/"/g, "")
      .replace(" ", "T")
      .concat(".000Z");

  const dateValue = Date.parse(newDate);
  const dateObject = new Date(dateValue)
  
  return dateObject;
}