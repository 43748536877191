<template>
  <div class="icons-info">
    <div class="icons-status" v-if="!isCodeShow" ref="iconsNav">
      <img
        v-for="(icon, index) in icons"
        @click="handleClick(index)"
        :src="getIconFileName(icon)"
        :key="index"
        :class="{ 'hover': currentIndex === index }"
      />
    </div>

    <div class="icon-message">
      <status-message
        :show-connection-status="showConnectionStatus"
        :connection-status="connectionStatus"
        ref="connectionStatusComponent"
      ></status-message>
    </div>

    <div class="code-auth" v-show="isCodeShow">
      <code-auth />

      <button v-on:click="hideCodeAuth">
        Voltar
      </button>
    </div>

    <div class="video-player-container" v-if="showPlayer">
      <video-player
        :midias="terminalMidias"
        :showPlayer="showPlayer"
        @close-video="closePlayer"
      />

      <button class="close-button" v-on:click="closePlayer" v-if="showCloseButton" :class="{ hidden: hideCloseButton }">
        Fechar
      </button>
    </div>
  </div>
</template>

<script>
import StatusMessage from '../status-message/status-message.vue';
import CodeAuth from '../code-auth/code-auth.vue';
import VideoPlayer from '../video-player/video-player.vue';
import controlKeys from '@/config/controlMap';
import iconsMixin from '@/mixins/iconsMixin'

export default {
  name: 'IconsSelector',
  mixins: [iconsMixin],
  components: {
    StatusMessage,
    CodeAuth,
    VideoPlayer
  },
  data () {
    return {
      icons: [
        { name: 'icons-conexao' },
        { name: 'icons-access' },
        { name: 'icons-getlist' },
        { name: 'icons-download' },
      ],
      currentIndex: 0,
      isCodeShow: false,
      showConnectionStatus: false,
      showPlayer: false,
      showCloseButton: true,
      connectionStatus: {
        message: '',
        class: ''
      },
    }
  },
  created() {
    document.addEventListener("keydown", this.handleKeyDown);
    this.hideCloseButtonTimeout();
  },
  destroyed() {
    document.removeEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    getIconFileName(icon) {
      const iconCut = icon.name.split('-');
      const hasExtraPart = iconCut.length > 2;
      const fileName = hasExtraPart
        ? `${iconCut[0]}-${iconCut[1]}-${iconCut[iconCut.length - 1]}.png`
        : `${iconCut[0]}-${iconCut[1]}.png`;

      return `assets/icons/${fileName}`;
    },
    handleKeyDown(event) {
      const key = event.keyCode;
      this.handleKey(key);
    },
    handleClick(index) {
      const key = index === this.currentIndex ? controlKeys.enterKey : index < this.currentIndex ? controlKeys.leftKey : controlKeys.rightKey;
      this.handleKey(key);
    },
    handleKey(key) {
      const { leftKey, rightKey, enterKey } = controlKeys;
      const lastIndex = this.icons.length - 1;

      switch (key) {
        case leftKey:
          this.currentIndex = (this.currentIndex === 0) ? lastIndex : this.currentIndex - 1;
          break;
        case rightKey:
          this.currentIndex = (this.currentIndex === lastIndex) ? 0 : this.currentIndex + 1;
          break;
        case enterKey:
          if (this.isCodeShow) {
            this.hideCodeAuth();
          } else if (this.showPlayer) {
            this.closePlayer();
          } else {
            const icon = this.icons[this.currentIndex];
            if (icon.name.includes('icons-conexao')) {
              this.checkInternet();
            } else if (icon.name.includes('icons-access')) {
              this.showCodeAuth();
            } else if (icon.name.includes('icons-getlist')) {
              this.checkContext();
            } else if (icon.name.includes('icons-download')) {
              this.checkMidias();
            }
          }
          break;
        default:
          break;
      }

      this.hideCloseButton = false;
      this.hideCloseButtonTimeout();
    },
    hideCloseButtonTimeout() {
      setTimeout(() => {
        this.hideCloseButton = true;
      }, 10000);
    },
  }
};
</script>

<style>
  @import './menu-nav.css'
</style>