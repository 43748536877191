
import axios from "axios";
import apiConfig from "@/config/apiConfig";
import optionsDate from "@/config/dateConfig";
import informTerminalErrors from "./informTerminalError";

async function informTerminalDateHour(context) {
  try {
    const data = new FormData();
    data.append('id_lcd', JSON.stringify({players: [context.data.id_lcd]}));
    data.append('banco', context.data.banco);
    data.append('dt_up', new Date().toLocaleString('en-GB', optionsDate).replace(',', ''));
    data.append('emulation', 0);
    data.append('download', 0);

    await axios.post(
      'https://api.wiplay.com.br/player/update/live',
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': apiConfig.dev.apiKey
        }
      }
    );
  }
  catch (error) {
    let terminal = {
      id_lcd: context.data.id_lcd,
      banco: context.data.banco
    }

    informTerminalErrors(terminal, error)
  }
}

async function informTerminalMemory(context) {
  try {
    const data = new FormData();
    data.append('id', context.data.id_lcd);
    data.append('banco', context.data.banco);
    data.append('hard_disk', '15 GB');
    data.append('memory', 15000000);
    data.append('total_download', '0');
    data.append('download', '0');

    await axios.post(
      'https://api.wiplay.com.br/player/update/info/player',
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': apiConfig.dev.apiKey
        }
      }
    );
  }
  catch (error) {
    let terminal = {
      id_lcd: context.data.id_lcd,
      banco: context.data.banco
    }

    informTerminalErrors(terminal, error)
  }
}

export default async function validateMedias () {
  try {
    const response = await axios.get(apiConfig.dev.apiUrlIdentification, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': apiConfig.dev.apiKey
      }
    });
    
    informTerminalDateHour(await response.data)
    informTerminalMemory(await response.data)

    return response;
  } catch (error) {
    console.log(error)
  }
}