import axios from "axios";
import apiConfig from "@/config/apiConfig";

export default async function informTerminalErrors(terminal, context) {
  try {
    const data = new FormData();
    data.append('id_lcd', terminal.id_lcd);
    data.append('id_banco', terminal.banco);
    data.append('msg_erro', context.response.data.message);
    data.append('versao_app', '1.0.1');

    await axios.post(
      'https://api.wiplay.com.br/player/insert/exception',
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': apiConfig.dev.apiKey
        }
      }
    );
  }
  catch (error) {
    console.log(error)
  }
}
