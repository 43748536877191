import Vue from 'vue';
import Router from 'vue-router';
import Home from '../views/login-page/login-page.vue';
import PlayerVideo from '../components/video-player/video-player.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/video',
      name: 'player-video',
      component: PlayerVideo,
      props: (route) => ({ videoSrc: route.query.videoSrc })
    }
  ]
});
