import axios from "axios";
import apiConfig from "@/config/apiConfig";
import html2canvas from 'html2canvas';
import { base64StringToBlob } from 'blob-util';

export async function reportPrintScreen(context) {
  console.log(context)

  const canvas = await html2canvas(document.querySelector('.login-container'));
  const file = canvas.toDataURL('image/png');

  if (context) {
    try {
      const blob = base64StringToBlob(file.replace('data:image/png;base64,', ''), 'image/jpeg');
      const formData = new FormData();
      formData.append('id_player', context.id);
      formData.append('id_banco', context.banco);
      formData.append('file', blob, 'print.jpg');
      
      await axios.post(
        'https://api.wiplay.com.br/monitoramento/insert/midia/print/player',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': apiConfig.dev.apiKey
          }
        }
      );
    }
    catch (error) {
      console.log(error)
    }
  } else {
    console.log('Objeto context inválido ou propriedade id ausente.');
  }
}
