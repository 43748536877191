<template>
  <div class="connection-status" :class="[localConnectionStatus.class]" v-show="localShowConnectionStatus">
    {{ localConnectionStatus.message }}
  </div>
</template>

<script>
import { messages } from '@/config/langSelector';

export default {
  props: {
    showConnectionStatus: {
      type: Boolean,
      required: true
    },
    connectionStatus: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      localShowConnectionStatus: false,
      localConnectionStatus: {
        message: '',
        class: ''
      }
    };
  },
  watch: {
    showConnectionStatus(value) {
      this.localShowConnectionStatus = value;
    },
    connectionStatus(value) {
      this.localConnectionStatus = { ...value };
    }
  },
  methods: {
    showConnectionStatusMessage(message, isSuccess) {
      this.localShowConnectionStatus = true;

      const systemLanguage = navigator.language;

      let translatedMessage;

      if (Object.prototype.hasOwnProperty.call(messages, systemLanguage)) {
        translatedMessage = messages[systemLanguage][message];
      } else {
        translatedMessage = messages.default[message];
      }

      this.localConnectionStatus.message = translatedMessage;
      this.localConnectionStatus.class = isSuccess ? 'success-message' : 'error-message';

      setTimeout(() => {
        this.localShowConnectionStatus = false;
        this.localConnectionStatus.message = '';
        this.localConnectionStatus.class = '';
      }, 3000);
    }
  }
};
</script>

<style scoped>
  @import './status-message.css';
</style>
