import { verifyContext } from '@/utils/verifyContext';

export default {
  methods: {
    checkInternet() {
      if (navigator.onLine) {
        this.setIconsState('-success');
        this.showConnectionStatusMessage('connectSuccess', true);
      } else {
        this.setIconsState('-denied');
        this.showConnectionStatusMessage('connectError', false);
      }
    },
    setIconsState(suffix) {
      this.icons = this.icons.map(icon => {
        return { name: icon.name + suffix };
      });
    },
    showCodeAuth() {
      if (!this.isCodeShow && !navigator.onLine && !this.isAuth) {
        this.setIconsState('-denied');

        this.showConnectionStatusMessage('authError', false);
      } else {
        this.isCodeShow = true;
      }
    },
    hideCodeAuth() {
      this.isCodeShow = false;
    },
    verifyIfIsAuth() {
      const isAuthStorage = window.localStorage.getItem('isAuth');

      isAuthStorage === 'true'
        ? (this.isAuth = true)
        : (this.isAuth = false);
    },
    async checkContext() {
      this.verifyIfIsAuth();

      if (this.isAuth) {
        const terminalCheck = await verifyContext();

        this.terminalMidias = terminalCheck.midias;
        this.terminalInfo = terminalCheck.info;

        this.showConnectionStatusMessage('midiasSuccess', true);

        if (terminalCheck.info.reload && this.showPlayer) {
          setTimeout(() => {
            this.checkMidias();
          }, terminalCheck.info.reload * 100);
        }
      } else {
        this.icons.forEach(icon => {
          icon.name += '-denied';

          this.showConnectionStatusMessage('midiasError', false);
        });
      }
    },
    async checkMidias() {
      await this.checkContext();

      if (this.terminalMidias.length > 0) {
        this.showPlayer = true;
      } else {
        this.terminalMidias.push({
          vimeo: navigator.onLine
            ? 'https://wiplay.com.br/android/default/default.mp4'
            : 'https://wiplay.com.br/android/default/defaulsc.mp4',
        });

        this.showPlayer = true;
      }
    },
    showConnectionStatusMessage(message, isSuccess) {
      this.$refs.connectionStatusComponent.showConnectionStatusMessage(message, isSuccess);
    },
    closePlayer() {
      this.showPlayer = false;
      this.terminalMidias = [];
      this.terminalInfo = {};
    },
  },
};
